import React, { Fragment, useEffect } from 'react';
import brook from 'data/brook.json';
import { Helmet } from 'react-helmet';
import LayoutDefault from 'layouts/LayoutDefault';
import { Col, Container, Row } from 'react-bootstrap';
import Button from 'components/button';

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

const AppLink = () => {
  const os = getMobileOperatingSystem();

  useEffect(() => {
    // Redirect to play store link or home based on OS
    if (os === 'Android') {
      window.location.replace('market://details?id=com.airbag.app');
    }
  }, []);

  function rendercontent() {
    if (os === 'Android') {
      return (
        <div className="error-not-found">
          <Container fluid="sm">
            <Row>
              <Col xs={12}>
                <div className="error-inner text-center d-flex flex-column">
                  <h3 className="heading heading-h3 text-white">
                    Serás redirigido en unos segundos..
                  </h3>
                  <div className="error-text mt--20">
                    <p className="text-white">
                      O puedes dar click al siguiente botón para abir el enlace
                      a la aplicación.
                    </p>
                    <div className="error-button-group mt--40 d-flex justify-content-center align-items-center">
                      <div className="mr--40 return-button">
                        <Button
                          type="button"
                          btnText="Abrir aplicación"
                          btnStyle="bullet"
                          size="sd"
                          color="theme"
                          arrow={false}
                          onClick={() =>
                            window.location.replace(
                              'https://play.google.com/store/apps/details?id=com.airbag.app'
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else {
      return (
        <div className="error-not-found">
          <Container fluid="sm">
            <Row>
              <Col xs={12}>
                <div className="error-inner text-center d-flex flex-column">
                  <h3 className="heading heading-h3 text-white">
                    Aplicación no disponible para iOS
                  </h3>
                  <div className="error-text mt--20">
                    <p className="text-white">
                      Estamos trabajando en ello, próximamente estará disponible
                      para ti
                    </p>
                    <div className="error-button-group mt--40 d-flex justify-content-center align-items-center">
                      <div className="mr--40 return-button">
                        <Button
                          type={'link'}
                          btnText={'Ir al Inicio'}
                          btnLink={'/'}
                          arrow={false}
                          size={'sd'}
                          color={'theme'}
                          btnStyle={'bullet'}
                          className={'header-right-button'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
  }

  return (
    <Fragment>
      <Helmet>
        <title>{`Airbag App | ${brook.title}`}</title>
      </Helmet>

      <LayoutDefault className="template-color-1 template-font-1">
        {rendercontent()}
      </LayoutDefault>
    </Fragment>
  );
};

export default AppLink;
